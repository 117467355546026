@import '../../global/index.scss';

.dialog{
  @media #{$md}{
    max-width: 95% !important;
  }
}

.cp-modal{
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;

	&__background{
		z-index: 99999;
		background-color: rgba(40,40,40,.5);
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	&__content{
		z-index: 999999;
		background-color: #fff;
		padding: 20px;
		box-sizing: border-box;
		max-width: 80%;
		border-radius: 5px;
		position: relative;
		max-height: 90vh;
		overflow-y: auto;

		h1{
			color: $primary-blacker;
			margin: 0;
		}
	}

	&__close{
		background-color: transparent;
		font-size: 30px;
		color: $gray;
		position: absolute;
		top: 10px;
		right: 10px;

		&:hover{
			color: $primary-blacker;
			background-color: transparent;
		}
	}
}

.cp-pay{
  &__switcher{
    display: flex;

    &__option{
      width: 114px;
      cursor: pointer;
      height: 65px;
      position: relative;
      font-size: 13px;
      text-decoration: none;
      border: 1px solid #ccc;
      line-height: 16px;
      border-radius: 7px;
      padding: 19px;
      overflow: hidden;
      margin-right: 15px;
      margin-bottom: 10px;
      align-items: center;
      display: flex;
      justify-content: center;

      p{
        margin: 0;
        text-align: center;
      }

      &--active{
        border: 2px solid #639bd0;
        color: #639bd0;
      }
    }
  }

  &__method{
    border: 1px solid #ccc;
    border-radius: 7px;
    padding: 10px 15px 20px;
    margin-bottom: 25px;
  }

  &__pix{
    &__code {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding: 20px;
      border: 1px solid #ccc;
      margin: 0 auto;
      width: 210px;
      border-radius: 2px;
      img{
        margin-bottom: 5px;
        max-width: 200px;
      }
    }
    &__instructions p {
      margin-bottom: 0px !important;
      font-size: 0.8rem;
    }
  }

  
}

.cp-boleto{
  min-width: 100%;
  min-height: 500px;
}

.cp-send-document{
  &__text{
    font-size: 18px;
    margin: 0;
  }

  &__description{
    font-size: 14px;
    font-weight: 900;
    margin: 0;
    margin-bottom: 20px;
  }

  &__obs{
    font-size: 12px;
    margin: 0;
    margin-bottom: 20px;
  }
}