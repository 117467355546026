@import '../../../global/index.scss';

.pg-aditional-contents{
  background-color: #B3B3B3;

  &--nothing{
    background-color: #B3B3B3;
    height: 100%;
    display: flex;
    align-items:center;
    justify-content: center;
    font-family: 'Maven Pro', sans-serif;
    font-weight: 900;
    font-size: 20px;
    flex-direction: column;
    color: #292928;
    p{
      margin: 0;
    }

    &-button {
      color: #FFFFFF;
      font-family: $font_family;
      font-weight: 900;
      font-size: 20px;
      background-color: #2C2C2B;
      padding: 2px 25px;
      margin-top: 10px;;
      border-radius: 30px;
      width: fit-content !important;
      transition: all .2s;
      text-align: center;

      &:hover{
        background-color: #666664;
        color: #fff;
        text-decoration: none;
      }
    }
  }
}

.cp-aditional-contents{
  background-color: #B3B3B3;
  position: relative;
  z-index: 2;
  padding-top: 20px;
  margin-bottom: 20px;
  &__content{
    &__title{
      border-left: 100vw solid #292928;
      margin-left: -100vw;
    }
    h2{
      background-color: #292928;
      padding: 12px 0;
      font-family: 'Maven Pro', sans-serif;
      font-weight: 900;
      color: #fff;
      margin-bottom: 30px;
    }
  }
}

.cp-aditional-content{
  padding: 20px 5px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &-container{
    margin-bottom: 20px;
  }
  &__content{
    border-radius: 10px;
    background-color: #fff;
    width: 95%;
    height: 100%;

    @media #{$md}{
      width: 100%;
    }

    h3{
      text-align: center;
      word-break: break-all;
      font-size: 20px;
      font-family: 'Maven Pro', sans-serif;
      font-weight: 600;
      color: #2C2C2B;
      padding: 0 10px;
      margin: 10px 0 0;
    }

    h4{
      text-align: center;
      word-break: break-all;
      font-size: 12px;
      font-family: 'Maven Pro', sans-serif;
      font-weight: 400;
      color: #2C2C2B;
      padding: 0 10px;
    }

    p{
      margin: 0 20px 60px;
      text-align: center;
      word-break: break-all;
      font-family: 'Maven Pro', sans-serif;
      font-size: 15px;
      font-weight: 400;
      color: #2C2C2B;
    }

    &__logo{
      padding-top: 100%;
      width: 100%;
      position: relative; 
      img{
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }

  &__go{
    color: #292928;
    font-family: 'Maven Pro', sans-serif;
    font-weight: 900;
    font-size: 20px;
    background-color: $primary;
    padding: 2px 25px;
    border-radius: 30px;
    width: fit-content;
    transition: all .2s;
    text-align: center;
    margin-top:10px;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, -100%);

    &:hover{
      background-color: #666664;
      color: #fff;
      text-decoration: none;
    }
  }
}

ul.cp-aditional-content{
  list-style: none;
  padding: 0;
  .aditional-content__item, .content__item{
    width: 100%;
    font-size: 18px;
    color: #FFF;
    background-color: #373737 !important;
    margin: 10px 0px;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    a{
      display: block;
      color: #FFF;
      width: 100%;
      height: 100%;
    }

    &__unblock{
      font-size: 14px;
      background-color: #808080;
      border-radius: 5px;
      padding: 2px 10px;
      margin-left: 20px;
      font-weight: 600;
    }
  }
}