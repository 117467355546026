.cp-modal-lesson{
  &__preview{
    max-width: 100%;
    padding-top: 15px;
    img{
      max-width: 100%;
    }
  }
}

:global .modal{
  padding-left: 0 !important;
}