@import '../../global/index.scss';

.pg-course{
  background: #808080;

  p {
    word-wrap: break-word
  }

  &__menu {
    background-color: #292928;
    display: none;

    @media #{$md}{
      display: block;
    }

    ul {
      list-style: none;
      display: flex;
      vertical-align: center;
      font-family: $font-family;
      font-size: 20px;
      font-weight: 600;
      margin: 0;
      padding: 0;
      color: #fff;
      flex-direction: column;

      @media #{$md}{
        font-size: 25px;
        flex-direction: row;
      }

      li{
        cursor: pointer;
        padding: 10px;
      }
    }
  }

  &__price-title{
    background-color: $primary;
    font-family: $font-family;
    font-size: 35px;
    font-weight: 800;
    padding: 10px 0;
    color: #fff;
    text-align: center;
  }

  &__alert{
    background-color: #f5c31f;
    color: #000000;
    text-shadow: 0px 3px 6px #00000029;
    font-family: $font-family;
    font-size: 20px;
    font-weight: 900;
    text-align: center;
    padding: 10px;

    a{
      display: block;
      color: $primary-blacker;
      transition: all .5s;
    }
  }

  &__contents {

    p {
      word-wrap: break-word
    }
  
    h2 {
      background: #4D4D4D 0% 0% no-repeat padding-box;
      margin-bottom: 30px;
      font-family: $font-family;
      font-size: 25px;
      text-align: center;
      font-weight: 900;
      color: #fff;
      padding: 10px 0;

      @media #{$md}{
        font-size: 40px;
      }
    }

    &__buyed {
      background-color: #0CB59F;
      color: #FFFFFF;
      text-shadow: 0px 3px 6px #00000029;
      font-family: $font-family;
      font-size: 20px;
      font-weight: 900;
      text-align: center;
      padding: 10px;

      @media #{$md}{
        font-size: 30px;
      }
    }

    &__alert{
      background-color: #f5c31f;
      color: #FFFFFF;
      text-shadow: 0px 3px 6px #00000029;
      font-family: $font-family;
      font-size: 20px;
      font-weight: 900;
      text-align: center;
      padding: 10px;

      @media #{$md}{
        font-size: 30px;
      }
    }

    &__error{
      background-color: #b30202;
      color: #FFFFFF;
      text-shadow: 0px 3px 6px #00000029;
      font-family: $font-family;
      font-size: 20px;
      font-weight: 900;
      text-align: center;
      padding: 10px;

      @media #{$md}{
        font-size: 30px;
      }
    }

    &__empty{
      font-family: $font-family;
      font-size: 20px;
      font-weight: 700;
      text-align: center;
    }
  }
}

.cp-speaker{
  border-bottom: 1px solid #fff;
  margin-bottom: 20px;
  padding: 10px 0 20px;

  &__title{
    font-size: 35px;
    border-bottom: 1px solid #808080;
    margin-bottom: 5px !important;
  }

}

.cp-card{
  background-color: #373737 !important;
  button, button:hover{
    color: #FFF;
    font-size: 20px;
  }
}


.cp-schedule{
  background-color: #B3B3B3;
  position: relative;
  z-index: 2;
  padding: 20px 0;
  margin-top: 100px;

  &:before {
    background: inherit;
    content: '';
    display: block;
    height: 50%;
    left: 0;
    position: absolute;
    right: 0;
    z-index: -1;
  }

  &:before {
    top: 0;
    -webkit-transform: skewY(-2.8deg);
            transform: skewY(-2.8deg);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
  }

  &:after {
    background: inherit;
    content: '';
    display: block;
    background-color: #CCCCCC;
    height: 30px;
    left: 0;
    position: absolute;
    right: 0;
  }

  &:after {
    top: -10px;
    -webkit-transform: skewY(-2.8deg);
            transform: skewY(-2.8deg);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
  }

  &__decorator{
    &:before{
      display: block;
      position: absolute;
      top: -10px;
      background-image: url('../../assets/style.png');
      background-size: 150px 150px;
      width: 150px; 
      height: 150px;
      display: inline-block;
      content:"";
      z-index: -1;

      @media #{$md}{
        background-size: 250px 250px;
        width: 250px; 
        height: 250px;
      }
    }
  }

  h1{
    color: #2C2C2B;
    font-family: $font-family;
    font-size: 40px;
    text-align: center;
    font-weight: 900;

    @media #{$md}{
      font-size: 40px;
    }
  }
}