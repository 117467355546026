@import '../../../global/index.scss';

.speaker{
  &__action{
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 5px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    clear: both;
    
    svg{
      color: #373737;
      width: 18px;
      height: 18px;
    }

    &__name{
      font-weight: 700 !important;
      font-size: 18px;
    }

    &__local{
      font-weight: 400 !important;
      font-size: 16px;
    }
  }

  &__more{
    margin-bottom: 10px;
    text-align: justify;
    &__person{
      padding-top: 200px;
      width: 200px;
      position: relative; 
      margin: 0 auto;

      @media #{$md}{
        float: left;
        margin-right: 35px;
        margin-bottom: 7px;
      }

      img{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
}