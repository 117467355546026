@import '../../global/index.scss';

.pg-auth-header{
	background-color: #808080;
	h2{
		margin-top: 30px;
		background-color: #292928;
		color: #fff;
		text-align: center;
		margin-right: 10px;
		padding: 12px 0;
		font-family: 'Maven Pro', sans-serif;
		font-weight: 600;
		font-size: 30px;
		border-left: 100vw solid #292928;
		margin-left: -100vw;
		border-right: 100vw solid #292928;
		margin-right: -100vw;

		@media #{$md}{
			font-size: 40px;
			font-weight: 900;
		}
		
		&.login{
			@media #{$md} {
				border-right: 0;
				margin-right: 0;
			}
		}
		
		&.signup{
			@media #{$md} {
				border-left: 0;
				margin-left: 0;
			}
		}
	}
}

.pg-auth{
	background-color: #CBCBCB;
	color: #1D2226;
	font-family: 'Maven Pro', sans-serif;
	padding-top: 30px;
	padding-bottom: 60px; 

	@media #{$md}{
    padding: 30px 30px 60px;
  }

	:global .col-md-6:first-child{
		@media #{$md}{
			border-right: 1px solid #707070;
		}
	}

	h2{
		font-size: 20px;
		letter-spacing: 0px;
		text-align: center;
		font-weight: 900;
		padding-bottom: 10px;
		border-bottom: 2px solid #4D4D4D;
		width: fit-content;
		margin: 0 auto;
		margin-bottom: 30px;
	}

	p{
		color: #8A9EAD;
		text-align: center;
		@media #{$md}{
			max-width: 70%;
		}
		margin: 0 auto 20px;
		font-size: 18px;
		letter-spacing: -1px;

		a{
			color: #4D4D4D;
			text-decoration: underline;
			line-height: 0.95;
		}
	}

	button{
		background-color: #4D4D4D;
		border: 7px solid #fff;
		box-shadow: 0px 3px 6px #00000091;
		border-radius: 41px;
		font-family: 'Maven Pro', sans-serif;
		font-size: 25px;
		font-weight: 600;
		transition: all .2s;

		&:hover{
			background-color: #141414;
			border-color: #5c5c5c;
			color: #d3d3d3;
		}
	}

	:global .form-group{
		position: relative;
		margin-bottom: 20px;

		svg{
			position: absolute;
			top: 50%;
			left: 10px;
			transform: translateY(-50%);
			color: #7D7D7D;
			font-size: 20px;
		}

		.invalid-feedback{
			position: absolute;
			bottom: -20px;
			left: 20px;
		}
	}

	input, select{
		border: 1px solid #1A1A1A;
		border-radius: 100px;
		padding: 10px 10px 10px 40px;
		margin-bottom: 30px;

		&:focus {
			border: 1px solid transparent;
		}

	}

	select{
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
		padding-top: 8px;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 98%;
    background-position-y: 5px;
  }
}