@import '../../../global/index.scss';

.input{
  margin-bottom: 15px;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
}

.cp-requirements-read {
  margin: 0 0 15px 0;

  &__title{
    font-weight: 600;
    margin: 0;
  }
}
