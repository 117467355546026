@import "../../../../global/index.scss";

.cp-portal-footer{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #292928;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  font-size: 14px;
  font-family: 'Maven Pro', sans-serif;
  font-weight: 600;
  flex-direction: column;
  padding: 20px 0;
  text-align: center;

  @media #{$md}{
    flex-direction: row;
    height: 91px;
    font-size: 18px;
    padding: 0;
    text-align: left;
  }

  p{
    margin: 0px;

    @media #{$md}{
      display: flex;
      align-items: center;
    }
  }
  &__logo{
    img{
      max-height: 40px;
      margin-left: 10px;
      display: block;
      margin: 0 auto 20px;
      @media #{$md}{
        display: inline-block;
        margin: 0 0 0 10px;
      }
    }
  }
}