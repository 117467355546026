@import '../../../../global/index.scss';

.modal-dialog{
  @media #{$lg}{
    max-width: 95vw !important;
  }

  .email-errors{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  p.email-error{
    flex:1;
    min-width: 50%;
    margin-bottom: 0;
    text-align: center;
  }
}

.error {
  background-color: #f8d7da;
}
