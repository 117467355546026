@import "../../../global/index.scss";

.cp-course-content{
  background-color: #B3B3B3;
  .collapsables{
    & > div{
      margin: 10px 0; 
    }
  }

  &__buyed {
    background-color: #0CB59F;
    color: #FFFFFF;
    text-shadow: 0px 3px 6px #00000029;
    font-family: $font-family;
    font-size: 20px;
    font-weight: 900;
    text-align: center;
    padding: 10px;

    @media #{$md}{
      font-size: 30px;
    }
  }

  ul.cp-lessons, ul.cp-aditional-content{
    list-style: none;
    padding: 0;
    .lesson__item, .content__item{
      font-size: 18px;
      background-color: #373737 !important;
      margin: 10px;
      padding: 10px;
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      a{
        display: block;
        width: 100%;
        height: 100%;
      }

      &__unblock{
        font-size: 14px;
        background-color: #808080;
        border-radius: 5px;
        padding: 2px 10px;
        margin-left: 20px;
        font-weight: 600;
      }
    }
  }

  &__alert{
    background-color: #f5c31f;
    color: #FFFFFF;
    text-shadow: 0px 3px 6px #00000029;
    font-family: $font-family;
    font-size: 20px;
    font-weight: 900;
    text-align: center;
    padding: 10px;

    @media #{$md}{
      font-size: 30px;
    }
  }

  &__error{
    background-color: #b30202;
    color: #FFFFFF;
    text-shadow: 0px 3px 6px #00000029;
    font-family: $font-family;
    font-size: 20px;
    font-weight: 900;
    text-align: center;
    padding: 10px;

    @media #{$md}{
      font-size: 30px;
    }
  }

}

.cp-schedule{
  background-color: #B3B3B3;
  position: relative;
  z-index: 2;
  padding: 20px 0;
  margin-top: 20px;

  h1{
    color: #2C2C2B;
    font-family: $font-family;
    font-size: 40px;
    text-align: center;
    font-weight: 900;

    @media #{$md}{
      font-size: 40px;
    }
  }
}