@import '../../../../global/index.scss';

.cp-header{
	background-color: $gray;
  min-height: 55px;
  &__logo{
    img{
      max-height: 45px;
    }
  }

  &__content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    &__right{
      display: flex;
      align-items: center;

      a.principal{
        color: #FFFFFF;
        font-family: 'Maven Pro', sans-serif;
        font-weight: 600;
        font-size: 16px;
        background-color: #2C2C2B;
        border-radius: 13px;
        padding: 3px 10px;
        margin: 0 5px 0 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: fit-content;
        width: 150px;
        transition: all .2s;

        &:last-child{
          margin: 0;
        }

        &:hover{
          background-color: #666664;
          color: #fff;
          text-decoration: none;
        }
      }
    }
  }

  &__logged-menu{
    position: relative;
    .open-btn{
      margin: 0;
      border: 1px solid transparent;
      background-color: #373737;
      font-family: 'Maven Pro', sans-serif;
      font-weight: 500;
      font-size: 18px;
      color: #fff;
      padding: 5px 14px 5px 22px;
      display: flex;
      border-radius: 5px;
      align-items: center;
      display:none;

      svg{
        margin-left: 0px;
      }

      @media #{$md}{
        display: block;
        svg{
          margin-left: 7px;
        }
      }

      

      &--mobile{  
        display: block;
        padding: 5px 10px !important;
        @media #{$md}{
          display: none;
        }

      }
    }
    

  }

  &__collapsable-menu{
    display: none;
    position: absolute;
    box-sizing: border-box;
    min-width: 250px;
    top: 50px;
    right: 0;
    z-index: 100;
    background-color: #F2F2F2;
    border-radius: 5px;

    @media #{$md}{
      width: 100%;
    }

    &--open{
      display: block;
    }

    ul{
      padding: 5px 10px;
      margin: 0;
      li{
        list-style: none;
        padding: 5px 0;
        border-bottom: 1px solid #CACACA;

        &:last-child{
          border: none;
        }

        button, a{
          color: #646464;
          background-color: transparent;
          border: 1px solid transparent;
          font-family: 'Maven Pro', sans-serif;
          font-weight: 500;
          font-size: 18px;
          letter-spacing: 0.75px;
          width: 100%;
          text-align: left;
          padding: 0;
          display: flex;
          align-items: center;
          svg{
            margin-right: 10px;
          }

          &:hover{
            text-decoration: underline;
          }
        }
      }
    }
  }
}
