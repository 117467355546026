@import '../../../global/index.scss';

.cp-subscription-status{
  border-radius: 69px;
  width: fit-content;
  padding: 10px 30px;
  box-sizing: border-box;
  margin: 0 auto 30px;
  cursor: pointer;

  @media #{$md}{
    margin: 0 0 30px 0 ;
  }

  p{
    font-family: $font-family;
    font-size: 20px;
    color: #FFFFFF;
    text-align: center;
    margin: 0;
    font-weight: bold;

    span{
      color: #FFFFFF;
      font-size: 30px;
    }
  }
}