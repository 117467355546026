@import '../../../../global/index.scss';

.pg-enrolled{
  &__filter{
    padding: 10px 0;

    &__actions{
      margin-bottom: 10px;
      text-align: end;

      a{
        margin: 0px;

        &:first-child{
          margin-left: 0; 
        }
      }

      @media #{$md}{
        text-align: end;
        a{
          margin: 0 5px !important;
        }
      }
    }

    &__button{
      height: 100%;
      float: right;
      margin-left: 10px;
    }
  }

  &__table{
    :global .table td{
      padding: 7px .75rem;
    }
  }
}