.pg-user{
  &__actions{
    display: flex;
    padding: 0 0 10px;
    justify-content: flex-end;
    button{
      margin-left: 10px;
    }
  }
  &__infos{
    margin: 20px 0;
    h1 {
      font-size: 20px;
      letter-spacing: 0px;
      text-align: center;
      font-weight: 900;
      padding-bottom: 10px;
      border-bottom: 2px solid #4D4D4D;
      width: -moz-fit-content;
      width: fit-content;
      margin: 0 auto;
      margin-bottom: 30px;
      font-family: 'Maven Pro', sans-serif;
    }
    border-bottom: 1px dashed #4D4D4D;
  }
  &__info{
    &__container{
      flex: 1;
    }

    &__title{
      font-weight: bold;
      font-size: 14px;
      margin: 0
    }

    &__data{
      margin: 0 0 10px 0;
    }
  }
  &__companySelector{
    margin-bottom: 20px;
  }
  &__requirements{
    margin-top: 20px;
    h1 {
      font-size: 20px;
      letter-spacing: 0px;
      text-align: center;
      font-weight: 900;
      padding-bottom: 10px;
      border-bottom: 2px solid #4D4D4D;
      width: -moz-fit-content;
      width: fit-content;
      margin: 0 auto;
      margin-bottom: 30px;
      font-family: 'Maven Pro', sans-serif;
    }
  }
}