@import '../../../global/index.scss';

.loader-body{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: $gray;
  z-index: 9999;
}