@import '../../../global/index.scss';

.cp-checkoutinfo{
  font-family: $font-family;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 0 20px 30px;
  position: relative;
  margin-bottom: 20px;

  p {
    margin:  0
  }
  
  &__powered{
    background-color: #f1f1f1;
    border-top: 1px solid #eae6e6;
    margin: 0 -20px;
    text-align: center;
    padding: 15px 20px 40px;

    p{
      font-size: 14px;
      font-weight: 400;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      min-height: 20px;

      img{
        max-height: 20px;
        margin-left: 5px;
      }
    }
  }

  &__secure{
    background-color: rgb(124, 208, 99);
    box-sizing: border-box;
    color: rgb(255, 255, 255);
    display: block;
    font-size: 12px;
    font-weight: 700;
    height: 46px;
    left: -10px;
    padding-bottom: 10px;
    padding-left: 16px;
    padding-right: 12px;
    padding-top: 10px;
    position: absolute;
    text-transform: uppercase;
    top: 50px;
    width: 204.938px;
    z-index: 1;
    display: flex;
    align-items: center;
    p{
      margin: 0;
    }

    @media #{$md} {
      top: 65px;
    }

    @media #{$lg} {
      top: 50px;
    }

    svg{
      width: 30px;
      height: 30px;
      margin-right: 7px;
    }

    &:before{
      position: absolute;
      content: " ";
      left: 0;
      bottom: -10px;
      display: block;
      border-bottom: 10px;
      border-left: 10px solid rgba(0,0,0,0);
      border-right: 0 solid rgba(0,0,0,0);
      border-top: 10px solid #56a03f;
    }
  }

  h1{
    margin-top: 20px;
    font-family: $font-family;
    font-size: 22px;
    font-weight: 600;
    color: #555;
  }
  
  &__author {
    font-family: $font-family;
    display: flex;
    font-size: 12px;
    font-weight: 500;
    padding-bottom: 40px;
    border-bottom: 1px solid #ccc;
    &__logo{
      margin-right: 10px;
      padding-top: 40px;
      width: 40px;
      max-height: 40px;
      position: relative; 
      img{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    p{
      margin: 0;

      &.bold {
        font-weight: 900;
      }
    }
  }

  &__price {
    color: #555;
    font-size: 28px;
    font-weight: 700;
    padding: 40px 0;
    line-height: 0.8;

    .name {
      font-size: 20px;
      margin-bottom: 5px;
    }

    .description {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 10px;
    }

    .old-price{
      color: rgb(134, 134, 134);
      font-size: 16px;
      font-weight: 700;
      position: relative;
      width: fit-content;
      line-height: 1.2;
    
      &:before {
        position: absolute;
        content: "";
        left: 0;
        top: 50%;
        right: 0;
        border-top: 2px solid;
        border-color: inherit;
      
        -webkit-transform:rotate(-5deg);
        -moz-transform:rotate(-5deg);
        -ms-transform:rotate(-5deg);
        -o-transform:rotate(-5deg);
        transform:rotate(-5deg);
      }
    }


  }

  &__voucher{
    border: 1px solid #ced4da;
    border-radius: 10px;
    padding: 10px;
    p{
      font-family: $font-family;
      margin-bottom: 10px;
    }
    &__input{
      display: flex;
      font-family: $font-family;
      border: 1px solid #ced4da;
      border-radius: 5px;
      
      button {
        font-family: $font-family;
      }
      input{
        border: none;
      }
    }
  }
}