@import '../../global/index.scss';

.pg-validate{
  background-color: #808080;
  font-size: 20px !important;

  form{
    display: flex;
    background-color: #fff;
		box-shadow: 0px 2px 4px #00000091;
    border-radius: .25rem;
    margin: 20px 0;
    padding: 20px;

    :global .form-group{
      flex: 2;
      padding:0 10px;
      margin-bottom: 0 !important;
    }
  }
}

.cp-feedback{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  margin: 1rem auto;
  color: #842029;
  background-color: #f8d7da;
  border: 2px solid #f5c2c7;
  border-radius: .25rem;

  &--success{
    color: #0f5132;
    background-color: #d1e7dd;
    border-color: #badbcc;

    a{
      color: #0f5132;
      font-weight: 900;
      font-size: 20px;
      svg{
        font-size: 20px;
        margin-left: 5px;
      }
    }
  }

  svg{
    font-size: 7rem;
  }

  &__content{
    margin-left: 0.5rem;
    @media #{$md}{
      max-width: 30%;
    }
  }
}