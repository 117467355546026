@import '../../../global/index.scss';

.cp-user-info-courses{
  flex: 1;
  h1 {
    font-size: 20px;
    letter-spacing: 0px;
    text-align: center;
    font-weight: 900;
    padding-bottom: 10px;
    border-bottom: 2px solid #4D4D4D;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    margin-bottom: 30px;
    font-family: 'Maven Pro', sans-serif;
  }

  &__enrolls{
    flex: 1;

    &__item{
      display: flex;
      flex-direction: row;
      border: 1px solid #000;
      border-radius: 5px;
      padding: 5px;
      margin-bottom: 10px;
      
      &__image {
        margin-right: 15px;
        img {
          max-width: 100%;
          max-height: 20vh;
        }
      }

      &__content {
        flex: 1;

        p {
          margin: 0;
        }

        &__name {
          font-weight: 600;
        }
      }
    }
  }
}