@import '../../../global/index.scss';

.image-container{
  img{
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
    padding: 30px;
  }
}

.text-container {
  color: #fff;

  a{
    color: #0CB59F;
    text-decoration: underline;
  }
}

.document-container{
  a{
    font-family: 'Maven Pro', sans-serif;
    font-size: 35px;
    color: #FFF;
    margin: 0;
    padding: 30px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    margin: 0 auto;

    svg{
      margin-right: 20px;
    }

    &:hover{
      color: #FFF;
    }
  }
}

.video-container {
  position: relative; 
  margin-bottom: 20px;
  &__content { 
    padding: 30px;
    padding-bottom: 100vh;
    height: 0;
    overflow: hidden;
    position: relative;
    background-color: #FFFFFF;
  }

  &__logo {
    margin: 50px auto 0;
    padding-top: 300px;
    width: 300px;
    position: relative; 
    img{
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

.video-container iframe, .video-container object, .video-container embed { 
  position: absolute;
  width: calc(100% - 30px);
  margin: 0 auto;
  height: calc(100vh - 15px);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pg-aditionalContent{
  background-color: #373737;

  &__header{
    background: $primary 0% 0% no-repeat padding-box;
    margin-bottom: 30px;
    font-family: $font-family;
    font-size: 25px;
    text-align: center;
    font-weight: 700;
    color: #fff;
    padding: 10px 0;
    margin: 50px 0;
    position: relative;

    &__container {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
    }

    &__title{
      flex: 0 0 100%;
    }

    @media #{$md}{
      font-size: 35px;

      &__title{
        padding: 0 165px;
      }
    }  

    img {
      display: none;
      width: 165px;
      top: 5px;
      left: 10px;
      position: absolute;
      top: -33px;

      @media #{$md}{
        display: block;
      }
    }
  }

  &__back{
    background-color: $primary;
    color:#FFFFFF;
    font-family: $font-family;
    font-size: 20px;
    border-radius: 38px;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    transition: all 0.5s;
    margin-bottom: 60px;

    @media #{$md} {
      margin-left: calc(100% - 220px);
      max-width: 220px;
    }

    &:hover{
      color:#FFFFFF;
      background-color: $primary-blacker;
      text-decoration: none;
    }

    p{
      margin: 0;
    }

    svg{
      margin-right: 10px;
    }
  }

  &__partnerships{
    background-color: #FFFFFF;

    h2{
      background: #4D4D4D 0% 0% no-repeat padding-box;
      margin-bottom: 30px;
      font-family: $font-family;
      font-size: 35px;
      text-align: center;
      font-weight: 700;
      color: #fff;
      padding: 10px 0;
    }
  }
}

.cp-partnership{
  color: $primary;
  :hover{
    color: $primary;
    opacity: 0.9;
  }
  a{
    color: $primary;
  }
  &__logo{
    padding-top: 100%;
    width: 100%;
    position: relative; 
    img{
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  &__title{
    font-weight: 500;
    font-size: 20px;
    text-align: center;
  }
}