@import '../../../global/index.scss';

.cp-login{
  margin: 0 10px 70px;
  background-color: #fff;
  box-shadow: 0px 3px 20px #00000033;
  border-radius: 10px;
  position: relative;
  padding: 10px 10px 40px;

  @media #{$md}{
    padding: 47px;
  }

	button{
		position: absolute;
		left: 50%;
    bottom: -60px;
		transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    padding: 5px;
	}

  p{
    margin-top: 20px;
    margin-bottom: 20px;
    a{
      color: #8A9EAD;
      text-decoration: none;
      &:hover{
        text-decoration: underline;
      }
    }
  }

  .button{
    cursor: pointer;

    &:hover{
      text-decoration: underline;
    }
  }
}