@import '../../../global/index.scss';

.dialog{
  @media #{$md}{
    max-width: 95% !important;
  }
}

.cp-modal-details-body{
  h2{
    color: $primary;
    font-family: $font-family;
    font-weight: 900;
    margin-top: 15px;;
  }

  p{
    font-family: $font-family;
    font-weight: 700;
    font-size: 20px;
    margin: 0;

    span{
      font-weight: 400;
    }
  }
}


ul.cp-lessons{
  list-style: none;
  padding: 0;
  .lesson__item, .content__item{
    font-size: 18px;
    color: #FFF;
    background-color: #373737 !important;
    margin: 10px 0px;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    a{
      display: block;
      color: #FFF;
      width: 100%;
      height: 100%;
    }

    &__unblock{
      font-size: 14px;
      background-color: #808080;
      border-radius: 5px;
      padding: 2px 10px;
      margin-left: 20px;
      font-weight: 600;
    }
  }
}