@import '../../../global/index.scss';

.day{
  &__title{
    background-color: $primary-light;
    color: #373737 !important;
    padding: 7px;
    font-weight: 700 !important;
    font-size: 18px;
  }
}