@import '../../../global/index.scss';


.cp-subscription-more-details{
  background-color: #FFFFFF;
  padding: 50px 0;
  position: relative;
  &__title{
    border-left: 100vw solid #292928;
    margin-left: -100vw;

    h2{
      text-align: center;
      background-color: #4D4D4D;
      padding: 12px 0;
      font-family: $font-family;
      font-weight: 900;
      color: #FFFFFF;
      font-size: 20px !important;
      margin-right: 0;
      box-sizing: border-box;

      @media #{$md} {
        font-size: 2rem !important;
        width: 80%;
      }
    }
  }

  &__text{
    font-family: $font-family;
    font-size: 20px;
    color: #2C2C2B;
    text-align: center;

    @media #{$md} {
      font-size: 27px;
      width: 80%;
    }
  }

  &__button{
    background-color: #2C2C2B;
    box-shadow: 0px 3px 6px #000000DE;
    color: #FFFFFF;
    font-family: $font-family;
    font-weight: 900;
    font-size: 20px;
    padding: 10px 20px;
    border-radius: 38px;
    display: inline-block;
    margin-top: 20px;

    @media #{$md} {
      font-size: 27px;
      margin-top: 40px;
    }

    &:hover{
      background-color: $primary;
      text-decoration: none;
      color: #FFFFFF;
    }

  }

  &__img{
    position: absolute;
    display: none;

    @media #{$md} {
      display: block;
      width: 150px;
      right: 10vw;
      bottom: 125px;
    }

    @media #{$lg} {
      width: 200px;
      right: 20vw;
      bottom: 50px;
    }
  }
}