@import '../../global/index.scss';

.cp-partnership{
  background-color: #fff;
  color: #373737;
  padding: 10px 0;

  @media #{$md}{
    padding: 20px 0;
  }

  :hover{
    color: #373737;
  }
  a{
    color: #373737;

    :hover{
      color: #373737;
    }
  }
  &__logo{
    padding-top: 100%;
    width: 100%;
    position: relative; 
    img{
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  &__title{
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    margin: 0;
  }

  &-title{
    background-color: #4D4D4D;
    text-align: left;
    text-align: center;
    padding: 10px 0;

    h1{
      color: #FFFFFF;
      text-shadow: 0px 3px 6px #00000029;
      font-size: 30px;
      font-weight: 900;
      letter-spacing: 0px;
      font-family: 'Maven Pro', sans-serif;
    }

    @media #{$md}{
      h1{
        font-size: 40px;
      }
    }
  }
}