.pg-orders{
  width: 100%;
  margin: 30px 0;

  table{
    width: 100%;
    border: 1px solid #f2f2f2;
    tr{
      cursor: pointer;

      &:nth-child(even){
        background-color: #f2f2f2;
      }

      td, th {
        padding: 15px 5px;
      }
    }
  }
}