@import '../../../global/index.scss';

.cp-course-content{
  display: flex;
  padding: 50px 0;
  align-items: center;
  flex-direction: column;

  @media #{$md} {
    flex-direction: row;
  }

  img{ 
    display: block;
    width: 100%;
    max-width: 354px;
  }
  
  &__text{
    flex: 0 0 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    p{
      text-align: center;
      letter-spacing: 0px;
      color: #FFFFFF;
      opacity: 1;
      font-family: $font-family;
      font-size: 25px;
    }

    a {
      background-color: #292928;
      border-radius: 28px;
      text-align: center;
      letter-spacing: 0px;
      color: #FFFFFF;
      opacity: 1;
      padding: 10px 20px;
      font-family: $font-family;
      font-weight: bold;
      font-size: 25px;
      transition: all .2s;

      &:hover{
        background-color: $primary;
        color: #FFFFFF;
        text-decoration: none;
      }
    }
  }
}