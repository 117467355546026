.cp-modal-partnership{
  &__preview__container{
    max-width: 300px;
    margin-top: 15px;
  }
  &__preview{
    padding-top: 100%;
   background-color: #ced4da;
    width: 100%;
    position: relative;
    img{
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

:global .modal{
  padding-left: 0 !important;
}