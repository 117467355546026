.pg-company{
  &__filter{
    padding: 10px 0;

    &__actions{
      text-align: end;
    }
  }

  &__table{
    :global .table td{
      padding: 7px .75rem;
    }
  }
}