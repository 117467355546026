@import '../../../global/index.scss';

.collapsable{
  background-color:#373737 !important;

  &__title{
    font-family: 'Maven Pro', sans-serif;
    font-size: 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color:#373737 !important;
    color: #FFFFFF;
  }

  &__body{
    background-color:#525252 !important;
    font-family: 'Maven Pro', sans-serif;
    color: #FFFFFF;
    font-size: 14px;

    h1, h2, p, a {
      margin: 0;
      font-weight: 400;
    }

    a{
      color: #fff;
      &:hover{
        color: #fff;
      }
    }

    h1{
      font-size: 30px;
    }

    h2{
      font-size: 25px;
    }
  }
}