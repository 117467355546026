@import '../../../global/index.scss';

.pg-my-profile{
  background-color: #CBCBCB;

  h2{
		font-size: 30px;
		letter-spacing: 0px;
		text-align: center;
		font-weight: 900;
		padding-bottom: 10px;
		border-bottom: 2px solid #4D4D4D;
		width: fit-content;
		margin: 0 auto;
		margin-bottom: 30px;
    font-family: 'Maven Pro', sans-serif;
	}
}

.cp-my-profile{
  margin-top: 50px;
  background-color: #fff;
  box-shadow: 0px 3px 20px #00000033;
  border-radius: 10px;
  padding: 10px 10px 40px;

  @media #{$md}{
		margin: 50px;
    padding: 47px;
  }

	:global .form-group{
		position: relative;
		margin-bottom: 20px;

		svg{
			position: absolute;
			top: 50%;
			left: 10px;
			transform: translateY(-50%);
			color: #7D7D7D;
			font-size: 20px;
		}

		.invalid-feedback{
			position: absolute;
			bottom: -20px;
			left: 20px;
		}
	}

	input, select{
		border: 1px solid #1A1A1A;
		border-radius: 100px;
		padding: 10px 10px 10px 40px;
		margin-bottom: 30px;

		&:focus {
			border: 1px solid transparent;
		}

	}

  select{
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
		padding-top: 8px;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 98%;
    background-position-y: 5px;
  }
}

.cp-my-profile-extra{
  margin-top: 50px;
  background-color: #fff;
  box-shadow: 0px 3px 20px #00000033;
  border-radius: 10px;
  padding: 10px 10px 40px;

  @media #{$md}{
		margin: 50px;
    padding: 47px;
  }
}
