@import "../../../global/index.scss";

.cp-course-header{
  background-color: #292928;
  padding: 0px 0 40px;

  a{
    color: #0cb59f !important;
    text-decoration: underline;

    &:hover{
      color: #056155;
    }
  }

  :global .countBox{
    display: flex;
    font-weight: 600;
    flex-direction: row-reverse;
    .countBoxItem{
      flex: 1;
      text-align: center;
    }
  }

  @media #{$md} {
    min-height: 570px;
  }
  
  h1{
    background: #4D4D4D 0% 0% no-repeat padding-box;
    margin-bottom: 30px;
    font-family: $font-family;
    font-size: 40px;
    text-align: center;
    font-weight: 900;
    color: #fff;
    padding: 10px 0;

    @media #{$md}{
      font-size: 40px;
    }
  }

  &__info{
    padding: 30px 0;
    font-family: $font-family;


    a{
      color: #fff;
      padding-bottom: 15px;
      display: block;
      &:hover{
        color: #fff;
      }
    }

    &__countdown{
      max-width: 100%;
      margin: 20px 0;
      /*@media #{$md}{
        max-width: 350px;
      }*/
      :global div.countBox {
        background-color: #fff;
      }

      @media #{$md}{
        margin: 0;
      }
    }

    &__description{
      text-align: justify;
      h1, h2, p, a {
        margin: 0;
        color: #fff;
        font-weight: 400;
      }

      h1{
        font-size: 30px;
      }

      h2{
        font-size: 25px;
        font-family: $font-family;
        font-weight: 900;
        margin: 0;

        @media #{$md} {
          font-size: 40px;
        }
      }
    }

    &__enroll{
      background-color: $primary;
      border: none;
      border-radius: 5px;
      font-size: 5vw;
      padding: 5px 0;
      margin: 10px 0;
      width: 100%;
      @media #{$md}{
        font-size: 2vw;
      }
    }

    &__offeredby{
      padding: 20px 0 0;
      font-weight: 600 !important;
    }
  }

  &__logo{
    padding-top: 300px;
    width: 300px;
    position: relative; 
    margin: 0 auto;

    @media #{$md} {
      padding-top: 400px;
      width: 400px;
      float: left;
      margin: 0 50px 20px 0;
    }

    img{
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}