@import '../../global/index.scss';

.dialog{
  @media #{$md}{
    max-width: 95% !important;
  }
}

.cp-modal-details-body{
  h2{
    color: $primary;
    font-family: $font-family;
    font-weight: 900;
    margin-top: 15px;;
  }

  p{
    font-family: $font-family;
    font-weight: 700;
    font-size: 20px;
    margin: 0;

    span{
      font-weight: 400;
    }
  }
}