@import '../../../global/index.scss';

.lecture{
  display: flex;
  flex-direction: column;
  padding: 10px 0 20px;
  border-bottom: 5px solid #373737;

  @media #{$md} {
    flex-direction: row;
  }

  &__time{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: 1;

    @media #{$md} {
      flex-direction: column;
      margin-right: 10px;
      align-items: flex-start;
      justify-content: flex-start;
            
      p:first-child {
        margin-bottom: 20px;
      }
    }

    svg{
      color: #373737;
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }

    p{
      display: flex;
      align-items: center;
      font-size: 18px;
    }

    p:first-child {
      margin-bottom: 10px;
    }

  }

  &__info{
    flex: 3;
  }

  &__title{
    font-weight: 700 !important;
    font-size: 22px;
    margin-bottom: 10px !important;
    color: #373737;
  }
}