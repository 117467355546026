@import '../../../global/index.scss';

.cp-price{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 50px;

  &__box{
    background: #CBCBCB 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #707070;
    opacity: 1;
    padding: 9px;
    flex: 1;
  }

  &__info{
    background-color: $primary;
    padding: 7px;
    &__name{
      font-family: $font-family;
      font-size: 20px;
      text-align: center;
      font-weight: 600;
      color: #fff;
      margin: 0 0 7px;
    }

    &__price{
      font-family: $font-family;
      font-size: 27px;
      text-align: center;
      font-weight: 800;
      color: #fff;
      margin: 0;
    }

    &__plan-type{
      font-family: $font-family;
      font-size: 16px;
      text-align: center;
      font-weight: 600;
      color: #707070;
      margin: 0;
    }

    &__description{
      font-family: $font-family;
      font-size: 16px;
      text-align: center;
      font-weight: 600;
      color: #707070;
      margin-bottom: 20px;
    }
  }

  &__action{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &__btn {
      background-color: $primary;
      text-align: center;
      border: 0;
      color: #FFFFFF;
      font-size: 25px;
      font-weight: 800;
      font-family: $font-family;
      border-radius: 50px;
      margin-top: 25px;

      &--disabled {
        background-color: #707070;
        color: #2b2b2b;
        cursor: not-allowed !important;
      }
    }
  }
}