@import '../../../global/index.scss';

.pg-my-tickets{
  background-color: #292928;

  &--nothing{
    background-color: #292928;
    height: 100%;
    display: flex;
    align-items:center;
    justify-content: center;
    font-family: 'Maven Pro', sans-serif;
    font-weight: 900;
    font-size: 20px;
    color: #B3B3B3;
    p{
      margin: 0;
    }
  }
}

.cp-my-tickets{
  background-color: #292928;
  position: relative;
  z-index: 2;
  padding-top: 20px;
  &__content{
    &__title{
      border-left: 100vw solid #B3B3B3;
      margin-left: -100vw;
    }
    h2{
      background-color: #B3B3B3;
      padding: 12px 0;
      font-family: 'Maven Pro', sans-serif;
      font-weight: 900;
      color: #292928;
      margin-bottom: 30px;
    }
  }

  table, td, tr, th {
    color: #fff;
  }

  tr{
    &:hover{
      background-color:rgba(255, 255, 255, 0.2) !important;
    }
  }

  svg{
    color: $primary;
    cursor: pointer;
  }
}

.bold{
  font-weight: 600;
}