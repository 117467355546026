.pg-subscription{
  &__filter{
    padding: 10px 0;

    :global .row {
      margin-bottom: 10px;
    }

    &__actions{
      text-align: end;
    }

    &__button{
      height: 100%;
      float: right;
      margin-left: 10px;
    }
  }

  &__table{
    :global .table td{
      padding: 7px .75rem;
    }
  }
}