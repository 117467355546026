.pg-partnership{
  &__filter{
    padding: 10px 0;

    &__actions{
      text-align: end;
    }
  }

  &__table{
    :global .table td{
      padding: 7px .75rem;
    }
  }

  &__preview{
    max-width: 100px;
  }

  &__logo{
    padding-top: 100%;
   background-color: #ced4da;
    width: 100%;
    position: relative;
    img{
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}