@import '../../../../global/index.scss';

.pg-email{
  &__filter{
    &__actions{
      padding: 0 0 10px 0;
      text-align: end;
    }
  }

  &__table{
    :global .table td{
      padding: 7px .75rem;
    }
  }
}