@import '../global/index.scss';

.cp-layout{
  height: 100%;
  display: flex;
  flex-direction: column;
}

.cp-dash-layout{
  &__content{
    flex: 1 0 auto;

    &__sidebar{
      padding-left: 0 !important;
    }

    &__box{
      padding: 20px 30px 30px 15px;
    }
  }  
}

.cp-layout-loading{
  height: 100%;
  width: 100%;
  background-color: $gray;
}