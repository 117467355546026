@import '../../../global/index.scss';

.cp-signin{
  margin: 0 10px;
  background-color: #fff;
  box-shadow: 0px 3px 20px #00000033;
  border-radius: 10px;
  padding: 10px 10px 40px;

  @media #{$md}{
    padding: 47px;
  }
	button{
		position: absolute;
		bottom: -60px;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}