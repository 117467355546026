@import '../../../global/index.scss';

.cp-ticket-btn {
  position: absolute;
  bottom: 20px;
  right: 30px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-color: $primary;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 999;
}