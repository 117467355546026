@import '../../../../global/index.scss';

.cp-modal-attributes {
  &__options {
    &__add {
      button {
        float: right;
      }
    }
    &__remove{
      cursor: pointer;
      color: $primary;
    }
  }
}