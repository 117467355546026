@import '../../../../global/index.scss';

.onlyMobile{
  @media #{$lg}{
    display: none;
  }
}

.onlyDesktop{
  display: none;
  @media #{$lg}{
    display: block;
  }
}

.modal{
  padding-left: 0 !important;
  
}
.modal-dialog{
  @media #{$lg}{
    max-width: 95vw !important;
  }
}

.body-variables{
  background-color: rgb(231, 231, 231);
  padding: 20px;
  margin-bottom: 10px;

  h3{
    font-size: 18px;
    border-bottom: 1px solid $gray;
  }

  .variable{

    p{
      margin: 0;
    }

    &__var{
      font-weight: 900;
      padding-right: 15px;
    }

    &--required{
      color: red;
      padding-left: 5px;
    }

    &__description{

    }
  }
}