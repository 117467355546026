@import '../../global/index.scss';

.pg-Company{
  background: linear-gradient(90deg, #808080 50%, #292928 50%);

  &__prices{
    background-color: #808080;

    h1{
      background-color: $primary;
      text-align: center;
      margin: 0 0 50px 0;
      padding: 25px 0;
      font-family: $font_family;
      font-weight: 900;
      color: #fff;
    }
  }
}

.cp-company{
  background-color: #808080;
  padding-top: 50px;
  &__content{
    padding-bottom: 12.5vh;
    &__title{
      border-left: 100vw solid #292928;
      margin-left: -100vw;
    }
    h2{
      text-align: center;
      background-color: #292928;
      padding: 12px 0;
      font-family: $font_family;
      font-weight: 900;
      color: #fff;
      margin-bottom: 30px;
    }
  }

  &__title{
    font-family: $font_family;
    font-weight: 600;
    color: #292928;
    padding: 20px 0;
    text-align: center;
    font-size: 25px;
  }

  &__desc{
    font-family: $font_family;
    font-weight: 500;
    color: #FFFFFF;
    font-size: 18px;
    margin: 0;
    text-align: justify;
  }

  &__associate{
    font-family: $font_family;
    font-weight: 700;
    color: #FFFFFF;
    font-size: 25px;
    margin: 0;
    background-color: $primary;
    text-align: center;
    border-radius: 20px;
    padding: 10px;
    cursor: pointer;
    transition: all .2s;
    &:hover{
      text-decoration: none;
      box-shadow: 5px 5px 5px #303030;
      background-color: darken($primary, 10);
    }
  }

  &__logo{
    padding-top: 100%;
    width: 100%;
    position: relative; 
    img{
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

.cp-closed-courses{
  background-color: #B3B3B3;
  position: relative;
  z-index: 2;
  padding-top: 20px;
  padding-bottom: 12.5vh;

  p {
    word-wrap: break-word
  }


  &:before {
    background: inherit;
    content: '';
    display: block;
    height: 50%;
    left: 0;
    position: absolute;
    right: 0;
    z-index: -1;
  }

  &:before {
    top: 0;
    -webkit-transform: skewY(-2.8deg);
            transform: skewY(-2.8deg);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
  }

  &:after {
    background: inherit;
    content: '';
    display: block;
    background-color: #CCCCCC;
    height: 30px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: -1;
  }

  &:after {
    top: -10px;
    -webkit-transform: skewY(-2.8deg);
            transform: skewY(-2.8deg);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
  }

  h2{
    color: #2C2C2B;
    font-family: $font_family;
    font-size: 40px;
    text-align: center;
    font-weight: 900;
  }
}

.cp-open-courses{
  background-color: #292928;
  margin-right: -15px;
  padding-top: 50px;
  padding-bottom: 100px !important;

  p {
    word-wrap: break-word
  }

  a:hover {
    text-decoration: none;
  }

  @media #{$md}{
    padding-bottom: 0;
  }
    
  :global .col-md-6 {
    margin-bottom: 80px;

    @media #{$md}{
      margin: 0;
    }
  }

  &__title{
    border-right: 100vw solid #4D4D4D;
    margin-right: -100vw;
    h2{
      text-align: center;
      background-color: #4D4D4D;
      padding: 12px 0;
      font-family: $font_family;
      font-weight: 900;
      color: #fff;
      margin-bottom: 30px;
    }
  }

  @media #{$md} {
    border-left: 20px $gray solid;
  }

  &__logo{
    padding-top: 100%;
    width: 100%;
    position: relative; 
    img{
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

.cp-open-course{
  &__content{
    font-family: $font_family;
    color: #FFFFFF;
    font-size: 18px;
    text-align: center;
    position: relative;
    margin-bottom: 125px;


    p {
      word-wrap: break-word
    }
  
    .title{
      font-size: 25px;
      font-weight: 500;
    }

    .read_more{
      color: #292928;
      font-family: $font_family;
      font-weight: 900;
      font-size: 20px;
      background-color: #FFFFFF;
      padding: 2px 25px;
      border-radius: 30px;
      width: fit-content;
      margin-top: 20px;
      position: absolute;
      //bottom: -50px;
      transition: all .2s;
      text-align: center;
      left: 50%;
      transform: translate(-50%, 0);

      &:hover{
        background-color: #666664;
        color: #fff;
        text-decoration: none;
      }
      
      &--buyed{
        background-color: $primary;
        color: #292928;
        font-size: 25px;
        box-shadow: 0px 3px 6px #000000DE;
  
  
        &:hover{
          background-color: #09ab96 !important;
        }
        
        p{
          font-size: 15px;
          text-align: center;
          margin: 0;
        }
      }
    }

    &__logo{
      padding-top: 100%;
      width: 100%;
      position: relative; 
      img{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.cp-closed-course{
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: 100px;

  p {
    word-wrap: break-word
  }


  & > a {
    width: 100%;
  }

  a:hover {
    text-decoration: none;
  }

  &__content{
    padding: 10px;
    border-bottom-right-radius: 50px;
    border-bottom-left-radius: 50px;
    background-color: #FFF;
    min-width: 100%;
    height: 100%;

    h3{
      text-align: center;
      word-break: break-all;
      font-size: 20px;
      font-family: $font_family;
      margin: 10px 0 30px;
      font-weight: 600;
      color: #2C2C2B;
    }

    p{
      margin: 0 20px 40px;
      text-align: center;
      word-break: break-all;
      font-family: $font_family;
      font-size: 15px;
      font-weight: 400;
      color: #2C2C2B;
    }

    &__logo{
      padding-top: 100%;
      width: 100%;
      position: relative; 
      img{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }

  &__buy{
    color: #FFFFFF;
    font-family: $font_family;
    font-weight: 900;
    font-size: 20px;
    background-color: #2C2C2B;
    padding: 2px 25px;
    border-radius: 30px;
    width: fit-content !important;
    margin-top: 20px;
    position: absolute;
    bottom: -50px;
    transition: all .2s;
    text-align: center;

    &:hover{
      background-color: #666664;
      color: #fff;
      text-decoration: none;
    }

    &--buyed{
      background-color: $primary;
      color: #292928;
      font-size: 25px;
      box-shadow: 0px 3px 6px #000000DE;


      &:hover{
        background-color: #09ab96 !important;
      }
      
      p{
        font-size: 15px;
        text-align: center;
        margin: 0;
      }
    }
  }
}

.cp-closed-courses{
  &__decorator{
    &:before{
      display: block;
      position: absolute;
      top: -10px;
      background-image: url('../../assets/style.png');
      background-size: 150px 150px;
      width: 150px; 
      height: 150px;
      display: inline-block;
      content:"";
      z-index: -1;

      @media #{$md}{
        background-size: 250px 250px;
        width: 250px; 
        height: 250px;
      }
    }
  }
}