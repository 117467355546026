@import "../../global/index.scss";

.cp-company-home{
  background-color: #B3B3B3;
  padding: 30px 0;
  
  &__preview{
    padding-top: 100%;
    background-color: #ced4da;
    width: 100%;
    position: relative; 
    img{
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
    }
  }

  &__content{
    display: flex;
    flex-direction: column;
    background-color: #FFF;
    margin: 20px;
    border-radius: 7px;
    box-shadow: 0px 3px 20px #00000033;

    &:hover{
      text-decoration: underline;
      color:#373737;
    }
  }

  &__title{
    flex: 1;
    padding: 10px;
    font-family: 'Maven Pro', sans-serif;
    font-size: 22px;
    text-align: center;
    color:#373737;
    &:hover{
      text-decoration: underline;
      color:#373737;
    }
  }
}