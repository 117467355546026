@import "../../global/index.scss";

.cp-terms{
  padding: 30px 0;
  text-align: justify;

  h1{
    padding-bottom: 10px;
    text-align: center;
  }

  h2{
    padding-bottom: 10px;
    text-align: center;
  }

  h3{
    font-size: 1.5rem;
    font-weight: bold;
    text-align: left;
  }

  h4{
    font-size: 1.25rem;
    font-weight: bold;
    text-align: left;
  }
}