@import '../../../global/index.scss';

.cp-table{
  &__header{
    margin: 20px 0;

    &__actions{
      text-align: right;
      padding: 0;
    }
    
    &__searchable{
      padding: 0;
    }
  }

  &__pagination{
    justify-content: center;
  }

  &__column__actions{
    a, button {
      margin: 0 7px;
      padding: 0px;
      cursor: pointer;
      color: $primary;
      &:hover{
        color: lighten($primary, .8)
      }
    }
  }
}