@import '../../../global/index.scss';

.sidebar {
  min-height: 100vh !important;
  height: 100%;
  z-index: 100;
  padding: 30px 6px 0 !important;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  background-color: $dark-gray;

  :global .nav-item{
    border-bottom: 1px solid $gray;
    &:hover{
      background-color: $gray;
    }
    a {
      color: #fff;
      display: flex;
      align-items: center;
      padding: 10px 5px;
    }

    svg{
      margin-right: 10px;
    }
  }

  &__subsection{
    border-bottom: 1px solid $gray;
    color: #fff;
    margin: 0;
    padding: 20px 0 10px;
  }
}