$gray: #4D4D4D;
$dark-gray: #252525;
$primary: #0CB59F;
$primary-blacker: #023047;
$primary-light: #2fcab6;
$secondary: #fb8500;

$xs: 'only screen and (max-width: 575px)';
$sm: 'only screen and (min-width: 576px)';
$md: 'only screen and (min-width: 768px)';
$lg: 'only screen and (min-width: 992px)';
$xlg: 'only screen and (min-width: 1200px)';

$font-family: 'Maven Pro', sans-serif