@import '../../../global/index.scss';

.modal-auth{
	color: #1D2226;
	font-family: 'Maven Pro', sans-serif;
	padding-top: 30px;
	padding-bottom: 60px; 

	@media #{$md}{
    padding: 30px 30px 60px;
  }

	:global .col-md-6:first-child{
		@media #{$md}{
			border-right: 1px solid #707070;
		}
	}

	h2{
		font-size: 20px;
		letter-spacing: 0px;
		text-align: center;
		font-weight: 900;
		padding-bottom: 10px;
		border-bottom: 2px solid #4D4D4D;
		width: fit-content;
		margin: 0 auto;
		margin-bottom: 30px;
	}

	p{
		color: #8A9EAD;
		text-align: center;
		@media #{$md}{
			max-width: 70%;
		}
		margin: 0 auto 20px;
		font-size: 18px;
		letter-spacing: -1px;

		a{
			color: #4D4D4D;
			text-decoration: underline;
			line-height: 0.95;
		}
	}

	button{
		background-color: #4D4D4D;
		border: 7px solid #fff;
		box-shadow: 0px 3px 6px #00000091;
		border-radius: 41px;
		font-family: 'Maven Pro', sans-serif;
		font-size: 25px;
		font-weight: 600;
		transition: all .2s;
    margin: 0 auto;
    display: block;
		&:hover{
			background-color: #141414;
			border-color: #5c5c5c;
			color: #d3d3d3;
		}
	}
}