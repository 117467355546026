@import '../../../global/index.scss';

.cp-company-content{
  padding: 0px 0 50px;
  z-index: 9999;
  &__title{
    border-right: 80vw solid #4D4D4D;
    margin-right: -50vw;
    margin-left: 20vw;

    @media #{$md} {
      margin-right: -55vw;
      margin-left: 30vw;
    }
    h2{
      text-align: center;
      background-color: #4D4D4D;
      padding: 12px 0;
      font-family: $font-family;
      font-weight: 900;
      width: 300px;
      color: #FFFFFF;
      font-size: 20px !important;
      margin-right: 0;
      box-sizing: border-box;

      @media #{$md} {
        font-size: 2rem !important;
        width: auto;
      }
    }
  }

  &__text{
    font-family: $font-family;
    font-size: 20px;
    color: #FFFFFF;
    margin: 0 auto;
    text-align: center;

    @media #{$md} {
      font-size: 27px;
    }
  }

  &__button{
    background-color: #2C2C2B;
    box-shadow: 0px 3px 6px #000000DE;
    color: #FFFFFF;
    font-family: $font-family;
    font-weight: 900;
    font-size: 20px;
    padding: 10px 20px;
    border-radius: 38px;
    display: inline-block;
    margin-top: 20px;

    @media #{$md} {
      font-size: 27px;
      margin-top: 40px;
    }

    &:hover{
      background-color: $primary;
      text-decoration: none;
      color: #FFFFFF;
    }

  }
}